"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _history = require("history");

var _default = (0, _history.createBrowserHistory)();

exports.default = _default;